import React from 'react';
import { Modal, Spacing, Text, Link, FlatButton } from '@reservamos/elements';
import DescriptionIcon from './DescriptionIcon';
import { vandaLinks } from '../../constants/links';

const FaqModal = () => {
  return (
    <Modal
      modalTrigger={
        <FlatButton text="Ver preguntas frecuentes" type="info" size="S" />
      }
      title="Preguntas frecuentes"
      responsiveSize="L"
      closeIcon="ClosePrimary"
      titleColor="primaryStrong"
    >
      <Spacing vertical size="L">
        <DescriptionIcon
          icon="shared/faq/transport.svg"
          title="¿Qué es VANDA?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            Vanda es una plataforma digital que agrupa viajeros para llevarlos a
            diferentes destinos de México por medio de transportes turísticos.
            En Vanda ofrecemos opciones de viajes económicos y seguros con
            horarios definidos y garantizados desde y hacia puntos estratégicos
            de cada ciudad.
          </Text>
        </DescriptionIcon>

        <DescriptionIcon
          icon="shared/faq/question.svg"
          title="¿Vanda es confiable?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            En <strong>Vanda</strong> utilizamos proveedores de autotransporte
            de larga trayectoria y reconocimiento en México, por lo que tu viaje
            siempre estará operado por empresas seguras y experimentadas.
            Además, <strong>Vanda</strong> cuenta con el respaldo de{' '}
            <strong>Reservamos.mx</strong>, la plataforma líder para comprar
            boletos de autobús en línea. <strong>Reservamos.mx</strong>lleva más
            de 8 años moviendo viajeros y más de 1 millón de boletos vendidos.
            Por eso, cuando viajas en{' '}
            <strong>
              Vanda, viajas con respaldo de una plataforma reconocida, segura y
              confiable.
            </strong>
          </Text>
        </DescriptionIcon>

        <DescriptionIcon
          icon="shared/faq/shop.svg"
          title="¿Cómo comprar mi viaje?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            Te ofrecemos dos formas de realizar tu compra: puedes hacerlo en
            línea a través de <strong>www.vanda.app</strong> o llamándonos al{' '}
            <strong>55 12091270</strong> para recibir ayuda de uno de nuestros
            asesores.
          </Text>
        </DescriptionIcon>

        <DescriptionIcon
          icon="shared/faq/ticket.svg"
          title="Ya pagué mi viaje, ¿qué sigue?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Spacing vertical size="S">
            <Text size="S">
              Una vez que hayas efectuado el pago de tu viaje, recibirás tu
              itinerario por correo electrónico con todos los detalles del
              viaje. En caso de no recibir tu itinerario, envíanos un correo a{' '}
              <Link
                text="contacto@vanda.app"
                type="primaryStrong"
                href={vandaLinks.email}
                newTab
              />{' '}
              o llámanos al <strong>55 12091270.</strong>
            </Text>
            <Text size="S">
              Ante cualquier cambio o eventualidad con el viaje nos mantendremos
              en contacto contigo a través de mensaje o llamada telefónica.
            </Text>
          </Spacing>
        </DescriptionIcon>

        <DescriptionIcon
          icon="shared/faq/padlock.svg"
          title="¿Es seguro viajar en Vanda?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            En Vanda garantizamos que tu viaje cuenta con protocolos de
            bioseguridad: uso de gel antibacterial, desinfección del interior de
            las unidades y uso de cubrebocas. Además, cuando viajas en Vanda,
            cuentas con la Cobertura Vanda que te protege ante siniestros y
            accidentes durante todo el viaje, mientras estés dentro de la
            unidad.
          </Text>
        </DescriptionIcon>

        <DescriptionIcon
          icon="shared/faq/cancellation.svg"
          title="¿Qué opciones tengo si debo cancelar o cambiar mi viaje?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            En caso que requieras cambiar o cancelar tu viaje, comunícate con
            nuestro servicio de atención al cliente llamando al{' '}
            <strong>55 12091270</strong> o envíanos un correo a{' '}
            <Link
              text="contacto@vanda.app"
              type="primaryStrong"
              href={vandaLinks.email}
              newTab
            />{' '}
            Tienes hasta 4 horas antes para realizar un cambio o cancelación. En
            ningún caso se realizará devolución del monto pagado. En caso de
            cancelación el monto quedará como crédito a favor del viajero
            durante 25 días naturales, el cual podrá ser usado en cualquiera de
            los viajes disponibles en{' '}
            <Link
              text="www.vanda.app"
              type="primaryStrong"
              href="https://www.vanda.app/"
              newTab
            />{' '}
            En caso de un cambio, la fecha a la que se puede posponer no será
            mayor a 25 días naturales.
          </Text>
        </DescriptionIcon>

        <DescriptionIcon
          icon="shared/faq/bag.svg"
          title="¿Cuántas maletas puedo llevar?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            Cuando viajas con Vanda puedes llevar una <strong>maleta</strong> de
            hasta 25 kg y <strong>una mochila o bolso pequeño</strong> de hasta
            10 kg que podrás llevar contigo dentro de la unidad. Recuerda
            siempre llevar objetos personales y de valor contigo en la maleta de
            mano o mochila. Si tu maleta excede el límite permitido o
            transportas otro tipo de equipaje, por favor comunícate con nosotros
            ya que está sujeto a disponibilidad. Por tu seguridad y la de los
            demás, queda prohibido transportar solventes, tanques de gas,
            pinturas, corrosivos, armas, objetos punzo cortantes y/o explosivos.
            Para uso personal, está permitido llevar sillas de ruedas, muletas,
            etc.
          </Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon="shared/faq/dog.svg"
          title="¿Puedo viajar con mascotas?"
          sizeIcon="M"
          titleSize="M"
          titleWeight="semibold"
          isExpanded
        >
          <Text size="S">
            En Vanda <strong>no está permitido viajar con mascotas</strong>{' '}
            tanto en la bodega como dentro de la unidad, incluso utilizando un
            canil o jaula.
          </Text>
        </DescriptionIcon>

        <Link
          href={vandaLinks.termsAndConditions}
          type="primaryStrong"
          weight="bold"
          text="Términos y condiciones"
          newTab
        />
      </Spacing>
    </Modal>
  );
};

export default FaqModal;
