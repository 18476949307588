import React from 'react';
import { Modal, Spacing, Text } from '@reservamos/elements';
import PropTypes from 'prop-types';
import DescriptionIcon from '../molecules/DescriptionIcon';
import assetUrl from '../../utils/assetUrl';

const CovidModal = ({ modalTrigger }) => {
  return (
    <Modal
      modalTrigger={modalTrigger}
      responsiveSize="L"
      title="Medidas sanitarias durante el viaje"
      closeIcon="ClosePrimary"
      titleColor="primaryStrong"
    >
      <Spacing vertical size="L">
        <DescriptionIcon
          icon={assetUrl('icons/covid/facemask.png')}
          title="Uso de cubrebocas"
        >
          <Text size="S">Uso de cubrebocas durtante todo el viaje.</Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon={assetUrl('icons/covid/distance.png')}
          title="Distanciamiento"
        >
          <Text size="S">Mantén distancia al hacer la fila.</Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon={assetUrl('icons/covid/sanitizer.png')}
          title="Gel desinfectante"
        >
          <Text size="S">Utiliza desinfectante de manos.</Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon={assetUrl('icons/covid/cleaning.png')}
          title="Unidad sanitizada"
        >
          <Text size="S">La unidad se sanitiza 100% antes de cada viaje.</Text>
        </DescriptionIcon>
      </Spacing>
    </Modal>
  );
};

CovidModal.propTypes = {
  modalTrigger: PropTypes.node.isRequired,
};

export default CovidModal;
